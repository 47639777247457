<template>
  <div class="ⱦheader">
    <div class="logo">
      <h1>
        <img src="//6liu6.cn/lo.png" />
        <a href="javascript:;" style="font-family: mama;">6liu6全网热搜</a>
      </h1>
    </div>
    <div class="link">
      <h2>6liu6.cn</h2>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>