<template>
  <footer>
    <p>
  
    <p style="color:gray;font-size: 10px;">声明:本站资源来自互联网收集,仅供用于学习和交流,请遵循相关法律法规,本站一切资源不代表本站立场,如有侵权、不妥请联系本站站长删除</p>
    <p style="color:gray;font-size: 10px;">
    联系邮箱:one#6liu6.cn(#换成@) | <a style="color:gray;font-size: 10px;" href="https://beian.miit.gov.cn/">苏ICP备15036878号-2</a>
    </p>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>